import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
const Lockr = require("lockr");

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle request
    if (!request.url.includes('refresh')) {
      request = this.addAuthHeader(request);
    }

    // Handle response
    return next.handle(request).pipe(catchError(error => {
      return this.handleResponseError(error, request, next);
    }));
  }

  private addAuthHeader(request: HttpRequest<any>) {
    const token = Lockr.get('access_token');
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }

  handleResponseError(error, request?, next?) {
    // Invalid token error
    if (error.status === 401 && !request.url.includes('login')) {
      const refreshToken = Lockr.get('refresh_token');
      if (!refreshToken) {
        localStorage.clear();
        this.router.navigate(['/auth/login']);
        return throwError(error);
      }

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          Lockr.set('access_token', token.access_token);
          Lockr.set('refresh_token', token.refresh_token);
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(e => {
          localStorage.clear();
          this.router.navigate(['/auth/login']);
          return throwError(error);
        }));
    }

    // Access denied error
    if (error.status === 403) {
      // Show message
      // Logout
      localStorage.clear();
      this.router.navigate(['/auth/login']);
      return throwError(error);
    }

    return throwError(error);
  }
}
