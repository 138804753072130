import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { InstallationService } from '../services/installation.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

interface Installation {
  hoses: any;
  numStation: any;
  dispensaries: any;
  fiscal_address: any;
  start_date_operations: string;
  business_name: string;
  address: string;
  legal_representative_name: string;
  technical_representative_name: string;
  franchise: string;
  phone: string;
  email: string;
  rfc: string;
  cre_permission: string;
  previous_volume_standard: string;
  previous_volume_premium: string;
  previous_volume_diesel: string;
  products: string;
}

@Component({
  selector: 'ngx-add-station',
  templateUrl: './add-station.component.html',
  styleUrls: ['./add-station.component.scss'],
})
export class AddStationComponent implements OnInit {
  loading: boolean = false;
  logoFile: any = '';
  logoBase64: any = '';
  formData: FormData = new FormData();
  newStation: Installation = {
    business_name: '',
    numStation:'',
    legal_representative_name: '',
    technical_representative_name: '',
    address: '',
    fiscal_address: '',
    start_date_operations: '',
    dispensaries: '',
    hoses: '',
    franchise: '',
    phone: '',
    email: '',
    rfc: '',
    cre_permission: '',
    previous_volume_standard: '',
    previous_volume_premium: '',
    previous_volume_diesel: '',
    products: '',
  };

  constructor(
    protected ref: NbDialogRef<AddStationComponent>,
    private stationService: InstallationService,
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.ref.close();
  }

  loadImage(e) {
    if (!e.target.files.length) return;

    this.logoFile = e.target.files[0];

    const reader = new FileReader();

    reader.readAsDataURL(this.logoFile);

    reader.onload = (error: any) => {
      this.logoBase64 = error.target.result;
    };
  }

  async storeStation() {
    const dataHasEmptyValue = Object.values(this.newStation).find(x => (x === null || x === ''));
    if (dataHasEmptyValue || this.logoFile === '') {
      this.alert('Todos los campos son requeridos', 'error');
      return;
    }

    this.loading = true;
    this.formData.append('file', this.logoFile);
    this.formData.append('logoBase64', this.logoBase64);
    this.formData.append('business_name', this.newStation.business_name);
    this.formData.append('address', this.newStation.address);

    this.formData.append('fiscal_address', this.newStation.fiscal_address);
    this.formData.append('start_date_operations', this.newStation.start_date_operations);
    this.formData.append('dispensaries', this.newStation.dispensaries);
    this.formData.append('hoses', this.newStation.hoses);
    this.formData.append('products', this.newStation.products);

    this.formData.append('franchise', this.newStation.franchise);
    this.formData.append('phone', this.newStation.phone);
    this.formData.append('email', this.newStation.email);
    this.formData.append('rfc', this.newStation.rfc);
    this.formData.append('legal_representative_name', this.newStation.legal_representative_name);
    this.formData.append('cre_permission', this.newStation.cre_permission);
    this.formData.append('technical_representative_name', this.newStation.technical_representative_name);
    this.formData.append('previous_volume_standard', this.newStation.previous_volume_standard);
    this.formData.append('previous_volume_premium', this.newStation.previous_volume_premium);
    this.formData.append('previous_volume_diesel', this.newStation.previous_volume_diesel);
    this.formData.append('num_station', this.newStation.numStation);

    const [error] = await this.stationService.storeStation(this.formData);
    if (error) {
      this.loading = false;
      this.alert('Ocurrio un problema, intenta más tarde', 'error');
      return error;
    }
    this.alert('Estación creada con éxito');
    this.loading = false;
    return this.ref.close(true);
  }

  alert(title: string = 'asdasd', type: SweetAlertIcon = 'success', text: string = '') {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
    });
  }
}
