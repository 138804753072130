import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive [collapsedBreakpoints]="['xs', 'is', 'sm', 'md', 'lg']">

        <div class="w-100 d-flex align-items-center mb-4">
          <a href="https://ccd.softgas.mx/dashboard" target="_blank">
            <img src="/assets/images/logo/logo_rojo.png" alt="" class="img-fluid w-100 px-4 mt-3 mt-lg-0">
            </a>
        </div>


        <div class="w-100 d-flex align-items-center px-4 mb-4">
          <button (click)="activeStation = !activeStation" nbButton size="small" outline id="sidebarCollapse" status="info" class="w-100">
            Cambiar estación
            <span *ngIf="activeStation === false">▼</span>
            <span *ngIf="activeStation">▲</span>
          </button>
        </div>
        <ngx-menu></ngx-menu>

        <div class="d-flex flex-column justify-content-end align-items-center mt-5 mb-2">
          <p class="font-weight-bold">Necesitas ayuda ?</p>
          <div>
            <a href="https://wa.me/5212223398475" target="_blank">
              <i class="fab fa-2x fa-whatsapp text-success hover-text-tone-0"></i>
            </a>
          </div>
        </div>
      </nb-sidebar>

      <nb-layout-column class="px-3 h-100 mb-4" style="width: 100vw; overflow-x: hidden;">
        <ngx-station-info></ngx-station-info>
        <div class="wrapper d-flex align-items-stretch">
          <nav id="sidebar" style="height: 65vh" class="border-right">
            <ngx-list-stations #stations id="sidebarCollapse"></ngx-list-stations>
          </nav>
          <div class="container-fluid px-3">
            <ng-content select="router-outlet"></ng-content>
          </div>
        </div>
      </nb-layout-column>

      <nb-layout-footer fixed class="mt-5">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  activeStation = false;

  constructor() {
  }

  ngOnInit() {
    const sidebarCollapseButton = document.getElementById('sidebarCollapse');
    const sidebar = document.getElementById('sidebar');
    sidebarCollapseButton.addEventListener('click', function (evt: MouseEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      sidebar.classList.toggle('active');
    }, false);

    document.addEventListener('click', async (evt: MouseEvent) => {
      const classList = sidebar.classList;
      if (evt.target !== sidebar) classList.remove('active');
    }, false);
  }

  goToHelp() {
    window.open('https://wa.me/5212223398475', '_blank');
  }
}
