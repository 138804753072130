import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
const Lockr = require('lockr');
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Dashboard' }, { title: 'Perfil' }, { title: 'Cerrar sesión' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public router: Router,
  ) {
    menuService.onItemClick()
      .pipe(filter((tag) => {
        return tag.item.title === 'Cerrar sesión';
      }))
      .subscribe(_ => this.logout());

    menuService.onItemClick()
      .pipe(filter((tag) => {
        return tag.item.title === 'Perfil';
      }))
      .subscribe(_ => this.goToProfile());

    menuService.onItemClick()
      .pipe(filter((tag) => {
        return tag.item.title === 'Dashboard';
      }))
      .subscribe(_ => this.goToDashboard());

    menuService.onItemClick()
      .pipe(filter((tag) => {
        return tag.item.title === 'Ayuda';
      }))
      .subscribe(_ => this.goToHelp());
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.user = Lockr.get('user');

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['auth/login']);
  }

  goToProfile() {
    this.router.navigate(['/pages/profile']);
  }

  goToDashboard() {
    this.router.navigate(['/pages/splashboard']);
  }

  goToHelp() {
     window.open('https://wa.me/5212223398475', '_blank');
  }
}
