import {Component, Input, OnInit} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {InstallationService} from '../../../../services/installation.service';
import Swal, {SweetAlertIcon} from 'sweetalert2';

@Component({
  selector: 'ngx-edit-station',
  templateUrl: './edit-station.component.html',
  styleUrls: ['./edit-station.component.scss'],
})
export class EditStationComponent implements OnInit {
  @Input() station: any;
  loading: boolean = false;
  logoFile: any = '';
  logoBase64: any = '';
  formData: FormData = new FormData();
  constructor(
    protected ref: NbDialogRef<EditStationComponent>,
    private stationService: InstallationService,
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.ref.close();
  }

  loadImage(e) {
    if (!e.target.files.length) return;

    this.logoFile = e.target.files[0];

    const reader = new FileReader();

    reader.readAsDataURL(this.logoFile);

    reader.onload = (error: any) => {
      this.logoBase64 = error.target.result;
    };
  }

  validateForm() {
    this.updateStation()
  }

  async updateStation() {
    const dataHasEmptyValue = Object.values(this.station).includes('') || Object.values(this.station).includes(null);
    if (dataHasEmptyValue) {
      this.alert('Todos los campos son requeridos', 'error');
      return;
    }

    this.loading = true;

    if (this.logoBase64) {
      const data = new FormData();
      data.append('file', this.logoFile);
      data.append('logoBase64', this.logoBase64);
      const [errorImg, { data: imgUrl }]: any = await this.stationService.updateStationLogo(this.station.id, data);
      if (errorImg) { return this.alert('No se pudo actualizar estación, intente más tarde', 'error'); }
      this.station.logo = imgUrl;
    }

    const stationUpdate: any = {};

    stationUpdate.business_name = this.station.business_name;
    stationUpdate.acronym = this.station.acronym;
    stationUpdate.address = this.station.address;
    stationUpdate.franchise = this.station.franchise;
    stationUpdate.phone = this.station.phone;
    stationUpdate.email = this.station.email;
    stationUpdate.rfc = this.station.rfc;

    stationUpdate.fiscal_address = this.station.fiscal_address;
    stationUpdate.dispensaries = this.station.dispensaries;
    stationUpdate.start_date_operations = this.station.start_date_operations;
    stationUpdate.hoses = this.station.hoses;
    stationUpdate.products = this.station.products;

    stationUpdate.legal_representative_name = this.station.legal_representative_name;
    stationUpdate.cre_permission = this.station.cre_permission;
    stationUpdate.technical_representative_name = this.station.technical_representative_name;
    stationUpdate.previous_volume_standard = this.station.previous_volume_standard;
    stationUpdate.previous_volume_premium = this.station.previous_volume_premium;
    stationUpdate.previous_volume_diesel = this.station.previous_volume_diesel;
    stationUpdate.num_station = this.station.num_station;

    const [error] = await this.stationService.updateStation(this.station.id, stationUpdate);
    if (error) {
      this.loading = false;
      this.alert('Ocurrio un problema, intenta más tarde', 'error');
      return error;
    }
    this.alert('Estación actualizada con éxito');
    this.loading = false;
    return this.ref.close(true);
  }

  alert(title: string = 'asdasd', type: SweetAlertIcon = 'success', text: string = '') {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
    });
  }

}
