import {Component, Output, EventEmitter, OnInit} from '@angular/core';
import { InstallationService } from '../services/installation.service';
import { NbDialogService } from '@nebular/theme';
import { AddStationComponent } from '../add-station/add-station.component';
import { StationService } from '../services/stations.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-list-stations',
  templateUrl: './list-stations.component.html',
  styleUrls: ['./list-stations.component.scss'],
})
export class ListStationsComponent implements OnInit {
  @Output() refreshStation: EventEmitter<any> = new EventEmitter();
  stations = [];
  loading: boolean = false;
  activeStation: any = '';
  stationId: any;
  title;
  activeStationId = '';


  constructor(
    private installationService: InstallationService,
    private stationService: StationService,
    private dialog: NbDialogService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.getStations();
  }

  async getStations() {
    this.loading = true;
    const [error, result] = await this.installationService.getStations(true) as any;
    if (error) return error;
    if (result) {
      for await (const station of result.data) {
        if (station.hasOwnProperty('paid')) {
          if (station.paid) this.stations.push(station);
        } else {
          this.stations.push(station);
        }
      }
      this.activeStation = this.stations[0];
      const currentStation = await this.stationService.getActiveStation();
      this.activeStationId = currentStation;
      if (!currentStation && this.activeStation) this.stationService.setActiveStation(currentStation)
    }
    this.loading = false;
  }

  addStation() {
    this.dialog.open(AddStationComponent, {
      hasScroll: true,
    }).onClose.subscribe(stationCreated => stationCreated ? this.getStations() : 0);
  }

  setActiveStation(station) {
    this.activeStation = station;
    const currentStationId = window.location.href.split('/').pop();
    const newRoute = this.router.url.replace(currentStationId, station.id);

    this.router.navigateByUrl(newRoute);
    this.stationService.setActiveStation(station.id);
  }

  setActiveStationOld(station) {
    this.activeStation = station;
    this.stationService.setActiveStation(station.id);

    this.refreshStation.emit();
  }

}
