import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { InstallationService } from './installation.service';
import { MyPromise } from '../interceptors/myPromise.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';

const BASEURL = `${environment.baseEndpoint}/stationsv2`;

@Injectable({
  providedIn: 'root',
})

export class StationService {
  @Output() change: EventEmitter<String> = new EventEmitter();
  stationId: string;

  constructor(
    private http: HttpClient,
    private installationService: InstallationService,
    private myPromise: MyPromise,
    private router: Router,
    private route: ActivatedRoute,
  ) {

  }

  checkIfStationIdOrRefirect() {
    if (window.location.href.includes('undefined')) {
      window.location.href = '/pages/notificaciones';
    }
  }

  async getStationDataById(stationId: string) {
    return this.myPromise.to(this.http.get(`${BASEURL}/${stationId}`));
  }

  async setActiveStation(stationId: string){
    if (stationId) {
      this.stationId = stationId;
    } else {
      const [error, result] = await this.installationService.getStations(true) as any;
      if (error) return error;
      if (result) {
        let stations = [];
        for await (const station of result.data) {
          if (station.hasOwnProperty('paid')) {
            if (station.paid) stations.push(station);
          } else {
            stations.push(station);
          }
        }
        this.stationId = stations[0].id;
      }
    }

    if (this.stationId === undefined || this.stationId === 'undefined' || !this.stationId) {
      window.location.href = '/pages/notificaciones';
      return 0;
    }

    this.change.emit(this.stationId);
  }

  async getActiveStation() {
    if (this.route.snapshot.params.stationId) {
      this.stationId = this.route.snapshot.params.stationId;
      return this.route.snapshot.params.stationId;
    } else {
      const [error, result] = await this.installationService.getStations(true) as any;
      if (error) return error;
      if (result && result.data.length) {
        const stations = [];
        for await (const station of result.data) {
          if (station.hasOwnProperty('paid')) {
            if (station.paid) stations.push(station);
          } else {
            stations.push(station);
          }
        }
        this.stationId = stations[0].id;
        return stations[0].id;
      }
    }
  }

  public getRouteStationId(): string {
    return this.getRouteData('stationId');
  }

  private getRouteData(data: string): any {
    const root = this.router.routerState.snapshot.root;
    return this.lastChild(root).data[0][data];
  }

  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.lastChild(route.firstChild);
    } else {
      return route;
    }
  }

}
