import { Component, OnInit } from '@angular/core';
import { StationService } from '../services/stations.service';

@Component({
  selector: 'ngx-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  stationId: string;

  constructor(
    private stationService: StationService,
  ) {
    this.stationService.change.subscribe((station: any) => { this.stationId = station; });
  }

  async ngOnInit() {
    this.stationId = await this.stationService.getActiveStation();
  }

}
