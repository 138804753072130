import { Pipe, PipeTransform } from '@angular/core';
const moment = require('moment');
moment.locale('es');

@Pipe({ name: 'unixDateFormat' })
export class UnixDateFormatPipe implements PipeTransform {
  transform(unixTime: any, format: string = 'LLL'): string {
    return moment.unix(unixTime).format(format);
  }
}
