import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyPromise {

  constructor() { }

  to(request: Observable<any>) {
    return new Promise((resolve) => {
      request.subscribe(data => {
        return resolve([null, data]);
      }, error => {
        return resolve([error]);
      });
    }) as any;
  }

  request(request: Observable<any>) {
    return new Promise((resolve, reject) => {
      request.subscribe(data => {
        return resolve(data);
      }, error => {
        return reject(error);
      });
    }) as any;
  }

}
