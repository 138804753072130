import { Pipe, PipeTransform } from '@angular/core';
const moment = require('moment');
moment.locale('es');

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

  transform(dateTime: string, format: string = 'LLL'): string {
    return moment(dateTime).format(format);
  }

}
