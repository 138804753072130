import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
const Lockr = require("lockr");
@Injectable()
export class AuthGuardLogin implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate() {
    const token = Lockr.get('access_token');

    if (token) {
      this.router.navigate(['pages/notificaciones']);
    } else {
      return true;
    }
  }
}
