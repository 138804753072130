import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {NbAuthOAuth2JWTToken, NbAuthService} from '@nebular/auth';
import { InstallationService } from './installation.service';
import { MyPromise } from '../interceptors/myPromise.service';
const Lockr = require("lockr");
const APIURL = `${environment.baseEndpoint}/users`;

@Injectable({
  providedIn: 'root',
})
export class SysUsersService {

  constructor(
    private http: HttpClient,
    private authService: NbAuthService,
    private installationService: InstallationService,
    private myPromise: MyPromise,
  ) { }
  async getToken() {
    return new Promise(resolve => {
      this.authService.onTokenChange()
        .subscribe(async (token: NbAuthOAuth2JWTToken) => {
          return resolve(token);
        });
    });
  }

  getUsers() {
    return this.myPromise.to(this.http.get(`${APIURL}`));
  }

  getUsersAll() {
    return this.myPromise.to(this.http.get(`${APIURL}/all`));
  }

  storeUser(user) {
    return this.myPromise.to(this.http.post(`${APIURL}`, user));
  }

  deleteUser(accountId, userId, enabled) {
    return this.myPromise.to(this.http.delete(`${APIURL}/account/${accountId}/user/${userId}?enabled=${enabled}`));
  }

  updateUser(user) {
    const userFromStorage = Lockr.get('user');
    const accountId = userFromStorage.account ? userFromStorage.account : userFromStorage.user_id;

    return this.myPromise.to(this.http.put(`${APIURL}/account/${accountId}/user/${user.id}`, user));
  }

  uploadSignature(accountId, signature, isSuperAdmin) {
    const isSuperAdminParam = isSuperAdmin ? '?isSuperAdmin=true' : '';
    return this.myPromise.to(this.http.put(`${APIURL}/${accountId}/signature${isSuperAdminParam}`, { signature }));
  }


  getProfile(accountId, isSuperAdmin = false) {
    const isSuperAdminParam = isSuperAdmin ? '?isSuperAdmin=true' : '';
    return this.myPromise.to(this.http.get(`${APIURL}/${accountId}${isSuperAdminParam}`));
  }

  checkIfUserCanUsePlatform(accountId, isSuperAdmin = false) {
    return new Promise(resolve => {
      let data = Lockr.get('initialData');
      if (data) {
        return resolve([null, data]);
      }

      const promises = [
        this.getProfile(accountId, isSuperAdmin),
        this.installationService.getStationsv2()
      ]

      Promise.all(promises).then(data => {
        const profile = data[0][1].data;
        const stations = data[1][1].data;

        const dataResponse = {
          signature: (profile.hasOwnProperty('signature') && profile.signature !== ''),
          firstStationExists: stations.length > 0,
        };

        Lockr.set('initialData', dataResponse);

        return resolve([null, data]);
      }).catch(error => {
        return resolve([error]);
      })
    })
  }
}
