import {EventEmitter, Injectable, Output} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MyPromise } from '../interceptors/myPromise.service';
import axios from 'axios'
import to from 'await-to-js'

const NEWSTATIONSAPIURL = `${environment.baseEndpoint}/stationsv2`;

@Injectable({
  providedIn: 'root',
})

export class InstallationService {
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private myPromise: MyPromise,
  ) { }

  getStations(short = false) {
    if (short) {
      return this.myPromise.to(this.http.get(`${NEWSTATIONSAPIURL}?short=true`));
    }

    return this.myPromise.to(this.http.get(`${NEWSTATIONSAPIURL}`));
  }

  getStationsv2(short = false) {
    if (short) {
      return this.myPromise.to(this.http.get(`${NEWSTATIONSAPIURL}?short=true`));
    }

    return this.myPromise.to(this.http.get(`${NEWSTATIONSAPIURL}`));
  }
  async storeStation(newInstallation: FormData) {
    const payload = localStorage.getItem('access_token')
    if (!payload) return [{message: 'Error contacte a soporte'}]
    const token = JSON.parse(payload)
    // return this.myPromise.to(this.http.post(`${NEWSTATIONSAPIURL}`, newInstallation));
    return await to(axios.post(`${NEWSTATIONSAPIURL}`, newInstallation, { headers: { authorization: token.data }}));

  }

  updateStation(stationId: string, station) {
    // tslint:disable-next-line: max-line-length
    return this.myPromise.to(this.http.put(`${NEWSTATIONSAPIURL}/${stationId}`, station));
  }

  async updateStationLogo(stationId: string, data: FormData) {
    const payload = localStorage.getItem('access_token')
    if (!payload) return [{message: 'Error contacte a soporte'}]
    const token = JSON.parse(payload)
    return await to(axios.put(`${NEWSTATIONSAPIURL}/${stationId}/logo`, data, { headers: { authorization: token.data }}));

  }

  reloadStations() {
    this.change.emit();
  }
}
