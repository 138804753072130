import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
const Lockr = require("lockr");
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
      private router: Router
    ) { }

    canActivate() {
        const token = Lockr.get('access_token');

        if (token) {
          return true;
        }

        return this.router.navigate(['auth/login']);
    }
}
