import {Component, Input, OnInit} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import Swal, {SweetAlertIcon} from 'sweetalert2';

@Component({
  selector: 'ngx-detail-station',
  templateUrl: './detail-station.component.html',
  styleUrls: ['./detail-station.component.scss'],
})
export class DetailStationComponent implements OnInit {
  @Input() station: any;
  loading: boolean = false;

  constructor(
    protected ref: NbDialogRef<DetailStationComponent>,
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.ref.close();
  }

  alert(title: string = 'asdasd', type: SweetAlertIcon = 'success', text: string = '') {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
    });
  }

}
