import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MyPromise } from './interceptors/myPromise.service';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
const AUTH_API = `${environment.baseEndpoint}/auth`;
const Lockr = require("lockr");

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private myPromise: MyPromise,
  ) { }

  async login( { email, password }) {
    const [error, data] = await this.myPromise.to(this.http.post(`${AUTH_API}/login`, { email, password })) as any;
    if (error) { return [error]; }
    localStorage.clear();
    await this.setSession(data);

    return [null];
  }

  /**
   * Set session variables
   * @param authResult Auth data from login response
   */
   public async setSession(authResult) {
    const tokenDecoded: any = jwt_decode(authResult.idToken);
    Lockr.set('user', tokenDecoded);
    Lockr.set('access_token', authResult.idToken);
    Lockr.set('refresh_token', authResult.refreshToken);
  }

  signup(data) {
    return this.myPromise.to(this.http.post(`${AUTH_API}/signup`, data)) as any;
  }

  recoverUser(email) {
    return this.myPromise.to(this.http.post(`${AUTH_API}/recover`, { email })) as any;
  }

  actAsUser(userId) {
    return this.myPromise.to(this.http.post(`${AUTH_API}/authAsUser/15UQ1FCgJzgGjVumFliQllSVwcL2`, {})) as any;
  }

  validateEmail(email: string, token: string) {
    return this.myPromise.to(this.http.post(`${AUTH_API}/validate-email`, {email, token}));
  }

  /**
   * Refresh token
   */
   refreshToken() {
    return new Observable((subscriber) => {
      const refreshToken = Lockr.get('refresh_token');

      this.http.post<any>(`${AUTH_API}/refresh`, {
        refresh_token: refreshToken
      }).toPromise().then(data => {
        subscriber.next(data);
      });
    });
  }
}
