import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { DetailStationComponent } from '../pages/splasboard/stations/detail-station/detail-station.component';
import { EditStationComponent } from '../pages/splasboard/stations/edit-station/edit-station.component';
import { StationService } from '../services/stations.service';
import { SysUsersService } from '../services/sys-users.service';
const moment = require('moment');
const Lockr = require('lockr');

@Component({
  selector: 'ngx-station-info',
  templateUrl: './station-info.component.html',
  styleUrls: ['./station-info.component.scss']
})
export class StationInfoComponent implements OnInit {
  stationId: string;
  station: any;
  showPanel: boolean = false;
  user: {
    email: '',
    name: '',
  };

  moment = moment;
  events: Array<any> = [];
  notifications = [];
  accountId: string = '';
  isSuperAdmin: boolean = false;
  message;

  constructor(
    private dialog: NbDialogService,
    private stationService: StationService,
    private userService: SysUsersService,
    private router: Router,
  ) {
    this.stationId = window.location.href.split('/').pop();

    this.stationService.change.subscribe(async (station: any) => {
      this.stationId = station;
      const [error, data] = await this.stationService.getStationDataById(this.stationId) as any;
      if (error) return;
      this.station = data.data;
    });
  }

  async ngOnInit() {
    await this.getUserData();

    const user = Lockr.get('user');
    if (user.account) {
      this.accountId = user.account;
    } else {
      this.accountId = user.user_id;
      this.isSuperAdmin = true;
    }
    const [errorUser, { data: userData }] = await this.userService.getProfile(this.accountId, this.isSuperAdmin);
    this.user.name = userData.name.split(' ')[0];
    if (errorUser) return errorUser;
    this.showPanel = (userData.hasOwnProperty('signature') && userData.signature !== '');

    this.init();
  }

  async init() {
    // Station
    this.stationId = await this.stationService.getActiveStation();
    const [error, data] = await this.stationService.getStationDataById(this.stationId) as any;
    if (error) return;
    this.station = data.data;
    if (this.station) this.showPanel = true;
  }

  detailStation(station) {
    this.dialog.open(DetailStationComponent, {
      hasScroll: true,
      context: {
        station: station,
      },
    });
  }

  editStation(station) {
    this.dialog.open(EditStationComponent, {
      hasScroll: true,
      context: {
        station: station,
      },
    });
  }

  async getUserData() {
    this.user = Lockr.get('user');
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['auth/login']);
    localStorage.clear();
  }

}
